<template>
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 256 256"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M128.047 67.881C144.306 67.881 157.487 54.7 157.487 38.4405C157.487 22.181 144.306 9 128.047 9C111.787 9 98.606 22.181 98.606 38.4405C98.606 54.7 111.787 67.881 128.047 67.881Z"
      stroke="currentColor"
      stroke-width="15"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M126.733 73.5391V238.516"
      stroke="currentColor"
      stroke-width="15"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M46.5191 157.378H15C15 157.378 28.5537 248 128.044 248C227.533 248 241.087 157.378 241.087 157.378H211.201"
      stroke="currentColor"
      stroke-width="15"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
